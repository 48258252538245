import { useForm } from "react-hook-form"
import PatientAuthWrapper from "../components/PatientAuthWrapper"
import FormGroup from "@/components/form/FormGroupInput"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { useNavigate } from "react-router-dom"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { Button } from "@/components/Button"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import ErrorMessage from "@/components/ErrorMessage"
import { PatientUser } from "../models/PatientUser"
import { useEffect } from "react"

type Inputs = {
  patientFirstName: string
  patientLastName: string
  patientPIN: string
  confirmPatientPIN: string
}

export default function PatientCompleteSignUp() {
  const navigate = useNavigate()
  const user = usePatientAuthStore((state: any) => state.user)
  const setUser = usePatientAuthStore((state: any) => state.setUser)

  useEffect(() => {
    if (!user || !user.userId) {
      navigate("/patients/auth")
      return
    }

    if (user.isVerified) {
      navigate("/patients/")
      return
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const createPatientMutation = useMutation({
    mutationFn: async (data: any) => {
      if (data.patientPIN !== data.confirmPatientPIN) {
        throw new Error("PINs do not match")
      }

      const req = {
        id: user.userId,
        pin: data.patientPIN,
        firstName: user?.firstName || data.patientFirstName,
        lastName: user?.lastName || data.patientLastName,
      }

      const res = await axios.post(
        import.meta.env.VITE_API_BASE_URL + "/patients/",
        req
      )

      const newUser: PatientUser = {
        id: res.data.data.id,
        email: res.data.data.email,
        firstName: res.data.data.firstName,
        lastName: res.data.data.lastName,
        phoneNumber: res.data.data.phoneNumber,
        isVerified: res.data.data.isVerified,
      }

      setUser(newUser)

      navigate("/patients/")
      return
    },
  })

  return (
    <SessionAuth requireAuth={true}>
      <PatientAuthWrapper>
        <form
          className="flex flex-col gap-7"
          onSubmit={handleSubmit(async (data) =>
            createPatientMutation.mutate(data)
          )}
        >
          <h1 className="text-xl font-medium">Complete signup</h1>

          {!user?.firstName && (
            <>
              <FormGroup
                id="patientFirstName"
                label="First Name"
                type="text"
                placeholder="Enter your first name"
                register={register("patientFirstName", {
                  required: {
                    value: true,
                    message: "Please enter your first name",
                  },
                })}
                error={errors.patientFirstName?.message}
              />
              <FormGroup
                id="patientLastName"
                label="Last Name"
                type="text"
                placeholder="Enter your last name"
                register={register("patientLastName", {
                  required: {
                    value: true,
                    message: "Please enter your last name",
                  },
                })}
                error={errors.patientLastName?.message}
              />
            </>
          )}
          <FormGroup
            id="patientPIN"
            label="PIN"
            type="password"
            inputMode="numeric"
            placeholder="Enter a 4 digit pin"
            register={register("patientPIN", {
              required: {
                value: true,
                message: "Enter a 4 digit pin",
              },
              maxLength: {
                value: 4,
                message: "PIN must be 4 digits",
              },
              minLength: {
                value: 4,
                message: "PIN must be 4 digits",
              },
            })}
            error={errors.patientPIN?.message}
          />
          <FormGroup
            id="confirmPatientPIN"
            label="Confirm PIN"
            type="password"
            inputMode="numeric"
            placeholder="Enter a 4 digit pin"
            register={register("confirmPatientPIN", {
              required: {
                value: true,
                message: "Enter a 4 digit pin",
              },
              maxLength: {
                value: 4,
                message: "PIN must be 4 digits",
              },
              minLength: {
                value: 4,
                message: "PIN must be 4 digits",
              },
            })}
            error={errors.patientPIN?.message}
          />

          {createPatientMutation.isError && (
            <ErrorMessage message={createPatientMutation.error.message} />
          )}
          <Button
            className="w-full"
            size="lg"
            role="link"
            type="submit"
            isLoading={createPatientMutation.isPending}
            disabled={createPatientMutation.isPending}
          >
            Submit
          </Button>
        </form>
      </PatientAuthWrapper>
    </SessionAuth>
  )
}
