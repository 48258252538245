import { Route, Routes, useNavigate } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import {
  HealthcareUser,
  HealthcareWorkerRole,
  useHealthcareUserStore,
} from "../stores/healthcareUserStore"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import HealthcareDashboard from "./HealthcareDashboard"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import HealthcareSettings from "./HealthcareSettings"
// import { useNotificationPermission } from "@/hooks/useRequestNotificationAccess"

export default function HealthcareHome() {
  // const notifcationPermission = useNotificationPermission()
  const setUser = useHealthcareUserStore((state: any) => state.setUser)

  const navigate = useNavigate()

  const query = useQuery({
    queryKey: ["healthcareLoginDetails"],
    queryFn: async () => {
      try {
        const response = await axios.get(
          import.meta.env.VITE_API_BASE_URL + "/healthcare/login-details"
        )

        const {
          role,
          hasSetIdDetails,
          hasUploadedLicense,
          hasAcceptedCommercialAgreement,
        } = response.data as HealthcareUser

        const isOwner = role === HealthcareWorkerRole.OWNER
        const hasNotCompletedSignUp =
          !hasSetIdDetails ||
          !hasUploadedLicense ||
          !hasAcceptedCommercialAgreement

        //check if signup flow has been completed.
        if (!isOwner && hasNotCompletedSignUp) {
          navigate("/healthcare/request-complete-signup")
          return null
        }

        if (!hasSetIdDetails) {
          navigate("/healthcare/auth/signup/verify-id")
          return null
        }

        if (!hasUploadedLicense) {
          navigate("/healthcare/auth/signup/upload-documents")
          return null
        }

        if (!hasAcceptedCommercialAgreement) {
          navigate("/healthcare/auth/signup/commercial-agreement")
          return null
        }

        setUser(response.data)
        return response.data
      } catch (error: any) {
        if (error.response?.status === 401) {
          navigate("/healthcare/auth")
          return null
        }
        if (error.response?.status === 403) {
          navigate("/unauthorized")
          return null
        }

        throw error
      }
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  return (
    <SessionAuth requireAuth={true}>
      <Navbar />
      <div className="mt-16 flex ">
        <Sidebar />
        <div className="md:ml-64 w-full">
          <Routes>
            <Route path="/*" element={<HealthcareDashboard />} />
            <Route path="/settings/*" element={<HealthcareSettings />} />
          </Routes>
        </div>
      </div>
    </SessionAuth>
  )
}
