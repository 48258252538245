import { create } from "zustand"
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from "@/utilities/localStorage"
import Session from "supertokens-web-js/recipe/session"
import { CountryCode } from "libphonenumber-js"

const localStorageKey = "patientAuthUser"

export const usePatientAuthStore = create((set) => ({
  user: getFromLocalStorage(localStorageKey),
  setSignUpDetails: (
    phoneNumber: string,
    countryCode: CountryCode,
    firstName: string,
    lastName: string
  ) =>
    set((state: any) => {
      const newState = {
        ...state.user,
        phoneNumber,
        countryCode,
        firstName,
        lastName,
      }
      setToLocalStorage(localStorageKey, newState)
      return {
        user: newState,
      }
    }),
  setUserId: (userId: string) =>
    set((state: any) => {
      const newState = {
        ...state.user,
        userId,
      }

      setToLocalStorage(localStorageKey, newState)
      return {
        user: newState,
      }
    }),
  setUser: (user: any) =>
    set(() => {
      setToLocalStorage(localStorageKey, user)
      return { user }
    }),
  signOut: () =>
    set(async () => {
      await Session.signOut()
      removeFromLocalStorage(localStorageKey)
      return { user: null }
    }),
}))
