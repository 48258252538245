import { Route, Routes, useNavigate } from "react-router-dom"
import BasicDetailsForm from "../components/auth/HealthcareSignUpBasicDetails"
import VerifyEmailForm from "../components/auth/HealthcareSignUpVerifyEmail"
import VerifyIdForm from "../components/auth/HealthcareSignUpVerifyId"
import UploadDocumentsForm from "../components/auth/HealthcareSignUpUploadDocuments"
import CommercialAgreement from "../components/auth/HealthcareSignUpCommercialAgreement"

export default function HealthcareSignUp() {
  const navigate = useNavigate()

  return (
    <Routes>
      <Route path="/" element={<BasicDetailsForm navigate={navigate} />} />
      <Route
        path="/verify-email"
        element={<VerifyEmailForm navigate={navigate} />}
      />
      <Route path="/verify-id" element={<VerifyIdForm navigate={navigate} />} />
      <Route
        path="/upload-documents"
        element={<UploadDocumentsForm navigate={navigate} />}
      />

      <Route path="/commercial-agreement" element={<CommercialAgreement />} />
    </Routes>
  )
}
