import { Route, Routes } from "react-router-dom"
import PatientSignUp from "./Pages/PatientSignUp"
import PatientLogIn from "./Pages/PatientLogIn"
import PatientCompleteSignUp from "./Pages/PatientCompleteSignUp"
import PatientsHome from "./Pages/PatientsHome"
import { useEffect } from "react"
import { setToLocalStorage } from "@/utilities/localStorage"
import PatientVerifyId from "./Pages/PatientVerifyId"

export default function PatientWrapper() {
  useEffect(() => {
    setToLocalStorage("tenantId", "patients")
  }, [])

  return (
    <main className="">
      <Routes>
        <Route path="/auth" element={<PatientSignUp />} />
        <Route path="/auth/login" element={<PatientLogIn />} />
        <Route
          path="/auth/complete-signup"
          element={<PatientCompleteSignUp />}
        />
        <Route path="/verify-id" element={<PatientVerifyId />} />
        <Route path="/*" element={<PatientsHome />} />
      </Routes>
    </main>
  )
}
