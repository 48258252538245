import { Button } from "@/components/Button"
import ErrorMessage from "@/components/ErrorMessage"
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/InputOtp"
import SuccessMessage from "@/components/SuccessMessage"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  clearLoginAttemptInfo,
  consumeCode,
  resendCode,
} from "supertokens-auth-react/recipe/passwordless"
import { usePatientAuthStore } from "@/Routes/Patient/stores/patientAuthStore"
import { ReloadIcon } from "@radix-ui/react-icons"

export default function VerifyOTPForm({
  setShowOtpPage,
}: {
  setShowOtpPage: Function
}) {
  const navigate = useNavigate()
  const setUserId = usePatientAuthStore((state: any) => state.setUserId)

  const [otp, setOtp] = useState("")

  const verifyMutation = useMutation({
    mutationFn: async () => {
      const response = await consumeCode({
        userInputCode: otp,
      })

      if (response.status === "OK") {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        await clearLoginAttemptInfo()
        setUserId(response.user.id)
        if (
          response.createdNewRecipeUser &&
          response.user.loginMethods.length === 1
        ) {
          // user sign up success
          navigate("/patients/auth/complete-signup/")
          return response.user
        } else {
          // user sign in success

          navigate("/patients/")
          return response.user
        }
      } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        // the user entered an invalid OTP
        throw new Error(
          "Wrong OTP! Please try again. Number of attempts left: " +
            (response.maximumCodeInputAttempts -
              response.failedCodeInputAttemptCount)
        )
      } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        throw new Error(
          "Old OTP entered. Please regenerate a new one and try again"
        )
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo()
        setShowOtpPage(false)
        throw new Error("Login failed. Please try again")
      }
    },
  })

  const resendMutation = useMutation({
    mutationFn: async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      const response = await resendCode()

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo()
        throw new Error("Login failed. Please try again")
      }

      return "OTP resent, please check your messages."
    },
  })

  return (
    <form className="flex flex-col gap-7">
      <h1 className="text-xl font-bold">We've sent you an SMS</h1>

      <p>
        Enter the code we’ve sent by text to your phone number.{" "}
        <Button
          variant="link"
          onClick={async () => {
            await clearLoginAttemptInfo()
            setShowOtpPage(false)
          }}
        >
          Change Phone Number.
        </Button>
      </p>

      <div className="flex flex-col gap-2">
        <label
          htmlFor="patientOTP"
          className="font-medium flex gap-2 items-center"
        >
          Enter code
          {verifyMutation.isPending && (
            <ReloadIcon className="animate-spin h-4 w-4 text-foreground" />
          )}
        </label>
        <InputOTP
          maxLength={6}
          id="patientOTP"
          value={otp}
          onChange={(e) => {
            setOtp(e)
            if (e.length === 6) {
              verifyMutation.mutate()
            }
          }}
          disabled={verifyMutation.isPending}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
      </div>

      {verifyMutation.isError && (
        <ErrorMessage message={verifyMutation.error.message} />
      )}
      {resendMutation.isError && (
        <ErrorMessage message={resendMutation.error.message} />
      )}
      {resendMutation.data && <SuccessMessage message={resendMutation.data} />}

      <p className="text-sm">
        Didn't get the SMS?{" "}
        <Button
          variant="link"
          disabled={verifyMutation.isPending || resendMutation.isPending}
          onClick={(event) => {
            resendMutation.mutate(event)
          }}
        >
          Send Again
        </Button>
      </p>
    </form>
  )
}
