import { Button } from "@/components/Button"
import logo from "@/assets/icons/logo.svg"
import { Link, useNavigate } from "react-router-dom"
import PatientAuthWrapper from "@/Routes/Patient/components/PatientAuthWrapper"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Session from "supertokens-web-js/recipe/session"
import ErrorPage from "./ErrorPage"
import LoadingPage from "./LoadingPage"

const queryKey = "getTenantId"
export default function Home() {
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const doesSessionExist = await Session.doesSessionExist()

      if (!doesSessionExist) {
        return false
      }
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/users/tenant-id"
      )

      if (result.data.tenantId === "patients") {
        navigate("/patients/")
        return result.data.tenantId
      }

      if (result.data.tenantId === "healthcare") {
        navigate("/healthcare/")
        return result.data.tenantId
      }

      return result.data.tenantId
    },
  })

  if (query.isError) {
    return <ErrorPage />
  }

  if (query.isLoading) {
    return <LoadingPage />
  }

  return (
    <PatientAuthWrapper>
      <img src={logo} alt="logo" className="w-full max-w-[200px] mx-auto" />

      <p className="text-center px-3 mb-10 text-lg">
        If you have a patient account, please log in. Otherwise, register for an
        account.
      </p>

      <Link to="/patients/auth/login" className="w-full">
        <Button className="w-full" size="lg" role="link">
          Log In
        </Button>
      </Link>
      <Link to="/patients/auth" className="w-full">
        <Button className="w-full" size="lg" role="link" variant="outline">
          Sign Up
        </Button>
      </Link>

      <p>
        Not a patient?{" "}
        <Link to="/healthcare/auth">Log in as a healthcare provider</Link>{" "}
      </p>
    </PatientAuthWrapper>
  )
}
