import { setToLocalStorage } from "@/utilities/localStorage"
import { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import HealthcareSignUp from "./HealthcareSignUp"
import HealthcareHome from "./HealthcareHome"
import HealthcareLogIn from "./HealthcareLogIn"
import InitiateForgotPassword from "./HealthcareForgotPassword"
import ResetPassword from "./HealthcareResetPassword"
import HealthcareRequestCompleteSignUp from "../components/auth/HealthcareRequestCompleteSignup"
import HealthcareInviteSignup from "../components/auth/HealthcareInviteSignup"

export default function HealthcareWrapper() {
  useEffect(() => {
    setToLocalStorage("tenantId", "healthcare")
  }, [])

  return (
    <main className="p-10">
      <Routes>
        <Route
          path="/auth/request-complete-signup"
          element={<HealthcareRequestCompleteSignUp />}
        />
        <Route path="/auth" element={<HealthcareLogIn />} />
        <Route path="/auth/signup/*" element={<HealthcareSignUp />} />
        <Route
          path="/auth/forgot-password"
          element={<InitiateForgotPassword />}
        />

        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/auth/invite/:id" element={<HealthcareInviteSignup />} />
        <Route path="/*" element={<HealthcareHome />} />
      </Routes>
    </main>
  )
}
