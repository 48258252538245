import { Button } from "@/components/Button"
import FormGroup from "@/components/form/FormGroupInput"
import PatientAuthWrapper from "@/Routes/Patient/components/PatientAuthWrapper"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { useState } from "react"
import VerifyOTPForm from "../components/VerifyOTPForm"
import PatientAuthHeadline from "../components/PatientAuthHeadline"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { useMutation } from "@tanstack/react-query"
import { createCode } from "supertokens-auth-react/recipe/passwordless"
import { CountryCode, parsePhoneNumber } from "libphonenumber-js"
import { validatePhoneNumber } from "@/utilities/validators"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { useToast } from "@/hooks/useToast"

export type Inputs = {
  phoneNumber: string
  countryCode: CountryCode
  patientFirstName: string
  patientLastName: string
}

export default function PatientSignUp() {
  const [showOtpPage, setShowOtpPage] = useState(false)

  return (
    <PatientAuthWrapper>
      {!showOtpPage ? (
        <SignUpForm setShowOtpPage={setShowOtpPage} />
      ) : (
        <VerifyOTPForm setShowOtpPage={setShowOtpPage} />
      )}
    </PatientAuthWrapper>
  )
}

function SignUpForm({ setShowOtpPage }: { setShowOtpPage: Function }) {
  const { toast } = useToast()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const setSignUpDetails = usePatientAuthStore(
    (state: any) => state.setSignUpDetails
  )

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const phoneNumber = parsePhoneNumber(data.phoneNumber, data.countryCode)

      const response = await createCode({
        phoneNumber: phoneNumber.number,
      })

      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in / up was not allowed.
        throw new Error(response.reason)
      }

      setSignUpDetails(
        data.phoneNumber,
        data.countryCode,
        data.patientFirstName,
        data.patientLastName
      )
      setShowOtpPage(true)

      return true
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <form
      className="flex flex-col gap-7"
      onSubmit={handleSubmit(async (data) => {
        await mutation.mutateAsync(data)
      })}
    >
      <PatientAuthHeadline text="Please type in your phone number to sign up" />

      <FormGroup
        id="patientFirstName"
        label="First Name"
        type="text"
        placeholder="Enter your first name"
        register={register("patientFirstName", {
          required: {
            value: true,
            message: "Please enter your first name",
          },
        })}
        error={errors.patientFirstName?.message}
      />
      <FormGroup
        id="patientLastName"
        label="Last Name"
        type="text"
        placeholder="Enter your last name"
        register={register("patientLastName", {
          required: {
            value: true,
            message: "Please enter your last name",
          },
        })}
        error={errors.patientLastName?.message}
      />

      <Controller
        name="countryCode"
        control={control}
        render={({ field }) => (
          <FormGroupSelect
            id="countryCode"
            label="Country Code"
            placeholder="Select Country Code"
            field={field}
            error={errors.countryCode?.message}
            options={[
              {
                name: "Kenya",
                value: "KE",
              },
            ]}
          />
        )}
      />

      <FormGroup
        id="phoneNumber"
        label="Phone Number"
        type="phone"
        placeholder="0712345678"
        register={register("phoneNumber", {
          required: {
            value: true,
            message: "Please enter your phone number",
          },
          validate: (value) => {
            if (
              !validatePhoneNumber({
                countryCode: watch("countryCode"),
                phoneNumber: value,
              })
            ) {
              return "Please enter a valid phone number"
            }

            return true
          },
        })}
        error={errors.phoneNumber?.message}
      />

      <Button
        className="w-full"
        size="lg"
        role="link"
        type="submit"
        disabled={mutation.isPending}
        isLoading={mutation.isPending}
      >
        Send OTP
      </Button>

      <Link to="/patients/auth/login" className="font-bold  mx-auto">
        Log In
      </Link>

      <p className="text-center">
        By continuing you agree to our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> and
        <Link to="/terms-of-service"> Terms of Service</Link>
      </p>
    </form>
  )
}
