import { Route, Routes, useNavigate } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "@/Routes/LoadingPage"
import PatientDashboard from "./PatientDashboard"
import { PatientUser } from "../models/PatientUser"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import PatientRequestMedicalInfo from "./PatientRequestMedicalInfo"
// import { useNotificationPermission } from "@/hooks/useRequestNotificationAccess"

const queryKey = "patientLoginDetails"
export default function PatientsHome() {
  const navigate = useNavigate()
  const setUser = usePatientAuthStore((state: any) => state.setUser)

  // const notifcationPermission = useNotificationPermission()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      //check if user has created a pin
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_BASE_URL + "/patients/login-details"
        )

        console.log(res)

        const { isVerified } = res.data

        if (!isVerified) {
          navigate("/patients/auth/complete-signup/")
          return null
        }

        const { idIsVerified } = res.data

        if (!idIsVerified) {
          navigate("/patients/verify-id")
          return null
        }

        setUser(res.data as PatientUser)
        return res.data as PatientUser
      } catch (error: any) {
        if (error.response?.status === 401) {
          navigate("/patients/auth/login")
          return null
        }

        if (error.response?.status === 403) {
          navigate("/unauthorized")
          return null
        }

        throw error
      }
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  return (
    <SessionAuth requireAuth={true}>
      <div className="px-5 sm:px-10 py-10 flex flex-col gap-7 max-w-[450px] mx-auto">
        <Routes>
          <Route path="/" element={<PatientDashboard />} />
          <Route
            path="/request-medical-info"
            element={<PatientRequestMedicalInfo />}
          />
        </Routes>
      </div>
    </SessionAuth>
  )
}
