import { Button } from "@/components/Button"
import { Link, useNavigate } from "react-router-dom"
import PatientPageWrapper from "./PatientPageWrapper"
import { usePatientAuthStore } from "../stores/patientAuthStore"
export default function PatientDashboard() {
  const signOut = usePatientAuthStore((state: any) => state.signOut)
  const navigate = useNavigate()

  return (
    <PatientPageWrapper title="Dashboard" isRoot={true}>
      <Link to="request-medical-info" className="w-full">
        <Button className="w-full">Request Medical Info</Button>
      </Link>
      <Button
        className="w-full"
        variant="outline"
        onClick={async () => {
          await signOut()
          navigate("/patients/auth")
        }}
      >
        Sign Out
      </Button>
    </PatientPageWrapper>
  )
}
