import { Route, Routes } from "react-router-dom"
import BillingSettings from "../components/settings/BillingSettings"
import UserManagementSettings from "../components/settings/UserManagementSettings"
import MyProfileSettings from "../components/settings/MyProfileSettings"
import HealthcareProviderSettings from "../components/settings/CareProviderProfileSettings"

export default function HealthcareSettings() {
  return (
    <div className="w-full">
      <Routes>
        <Route path="/" element={<div>Settings</div>} />
        <Route path="/billing" element={<BillingSettings />} />
        <Route path="/manage-users" element={<UserManagementSettings />} />
        <Route path="/my-profile" element={<MyProfileSettings />} />
        <Route
          path="/care-provider-profile"
          element={<HealthcareProviderSettings />}
        />
      </Routes>
    </div>
  )
}
